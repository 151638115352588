// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-search-recommended-js": () => import("./../../../src/pages/SearchRecommended.js" /* webpackChunkName: "component---src-pages-search-recommended-js" */),
  "component---src-templates-autocheck-js": () => import("./../../../src/templates/autocheck.js" /* webpackChunkName: "component---src-templates-autocheck-js" */),
  "component---src-templates-basic-contact-js": () => import("./../../../src/templates/basicContact.js" /* webpackChunkName: "component---src-templates-basic-contact-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blogs-js": () => import("./../../../src/templates/blogs.js" /* webpackChunkName: "component---src-templates-blogs-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/Home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-raw-html-js": () => import("./../../../src/templates/rawHTML.js" /* webpackChunkName: "component---src-templates-raw-html-js" */),
  "component---src-templates-raw-html-staff-js": () => import("./../../../src/templates/rawHTMLStaff.js" /* webpackChunkName: "component---src-templates-raw-html-staff-js" */),
  "component---src-templates-single-category-js": () => import("./../../../src/templates/singleCategory.js" /* webpackChunkName: "component---src-templates-single-category-js" */),
  "component---src-templates-vehicle-js": () => import("./../../../src/templates/Vehicle.js" /* webpackChunkName: "component---src-templates-vehicle-js" */)
}

